<template>
    <div class="card">
    <div class="card-header bg-info">
        <h5 class="card-title font-weight-semibold">Tingkat Kesadaran (GCS)</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered table-hover">
        <thead>
            <tr>
            <th>Pengkajian</th>
            <th>Nilai</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td class="table-warning" rowspan="4"><span class="font-weight-semibold">Respons Mata</span></td>
            
            <!-- ele pertama hardcode -->
            <td>
            <b-form-radio @input="isParent.inputData('response_mata')" v-model="isParent.row.aukptk_response_mata" name="aukptk_response_mata" :value="4">Dapat Membuka Mata Secara Spontan  <span class="text-blue-800 font-weight-semibold">(4)</span></b-form-radio>
            </td>

            </tr>
            <template v-for="(v,k) in (Config.mr.ksResponseMata||[])">
                <tr v-if="k != 0" :key="k">
                <td>
                    <b-form-radio @input="isParent.inputData('response_mata')" v-model="isParent.row.aukptk_response_mata" name="aukptk_response_mata" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                </td>
                </tr>
            </template>
        </tbody>
        <tbody>
            <tr>
            <td class="table-warning" rowspan="5"><span class="font-weight-semibold">Respons Verbal</span></td>
            
            <td>
            <b-form-radio @input="isParent.inputData('response_verbal')" v-model="isParent.row.aukptk_response_verbal" name="aukptk_response_verbal" :value="5">Pasien Sadar dan Merespons Pertanyaan dengan Benar<span class="text-blue-800 font-weight-semibold">(5)</span>
            </b-form-radio>
            </td>
            
            </tr>
            <template v-for="(v,k) in (Config.mr.ksResponseVerbal||[])">
                <tr v-if="k != 0" :key="k">
                <td>
                    <b-form-radio @input="isParent.inputData('response_verbal')" v-model="isParent.row.aukptk_response_verbal" name="aukptk_response_verbal" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                </td>
                </tr>
            </template>
        </tbody>
        <tbody>
            <tr>
            <td class="table-warning" rowspan="6"><span class="font-weight-semibold">Respons Motorik</span></td>
            
            <td>
            <b-form-radio @input="isParent.inputData('response_motorik')" v-model="isParent.row.aukptk_response_motorik" name="aukptk_response_motorik" :value="6">Pasien Dapat Melakukan Gerakan Sesuai Perintah<span class="text-blue-800 font-weight-semibold">(6)</span></b-form-radio>
            </td>
            
            </tr>
            <template v-for="(v,k) in (Config.mr.ksResponseMotorik||[])">
                <tr v-if="k != 0" :key="k">
                <td>
                    <b-form-radio @input="isParent.inputData('response_motorik')" v-model="isParent.row.aukptk_response_motorik" name="aukptk_response_motorik" :value="v.value">{{v.text}}<span class="text-blue-800 font-weight-semibold">({{v.value}})</span></b-form-radio>
                </td>
                </tr>
            </template>
        </tbody>
        <tfoot>
            <tr>
            <td class="table-info" colspan="9">
                <span class="font-weight-semibold">Nilai GCS: {{tingkatKesadaranTotal}}</span>
                <span v-if="tingkatKesadaranTotal >= 14" class="font-weight-semibold border-left ml-2 pl-2"> Composmentis</span> 
                <span v-else-if="tingkatKesadaranTotal >= 12 && tingkatKesadaranTotal <= 13" class="font-weight-semibold border-left ml-2 pl-2">Apatis</span>
                <span v-else-if="tingkatKesadaranTotal >= 10 && tingkatKesadaranTotal <= 11" class="font-weight-semibold border-left ml-2 pl-2">Delirium</span>
                <span v-else-if="tingkatKesadaranTotal >= 7 && tingkatKesadaranTotal <= 9" class="font-weight-semibold border-left ml-2 pl-2">Somonolen</span>
                <span v-else-if="tingkatKesadaranTotal >= 5 && tingkatKesadaranTotal <= 6" class="font-weight-semibold border-left ml-2 pl-2">Sopor</span>
                <span v-else-if="tingkatKesadaranTotal >= 4 && tingkatKesadaranTotal <= 4" class="font-weight-semibold border-left ml-2 pl-2">Semi-Comma</span>
                <span v-else class="font-weight-semibold border-left ml-2 pl-2">Coma</span>
            </td>
            </tr>
        </tfoot>
        </table>

        <!--
        <VValidate 
            name="Response Mata" 
            v-model="isParent.row.aukptk_response_mata" 
            :rules="toValidate(isParent.mrValidation.aukptk_response_mata)"
            class="d-block"
        />

        
        <VValidate 
            name="Response Verbal" 
            v-model="isParent.row.aukptk_response_verbal" 
            :rules="toValidate(isParent.mrValidation.aukptk_response_verbal)"
            class="d-block"
        />
        
        <VValidate 
            name="Response Motorik" 
            v-model="isParent.row.aukptk_response_motorik" 
            :rules="toValidate(isParent.mrValidation.aukptk_response_motorik)"
            class="d-block"
        />
        -->


    </div>
    </div>
</template>

<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')

export default{
    extends: GlobalVue,
    methods: {
        toValidate(val){
            return {...val}
        },
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        tingkatKesadaranTotal(){
            return (this.isParent.row.aukptk_response_mata||0) + 
            (this.isParent.row.aukptk_response_verbal||0) + 
            (this.isParent.row.aukptk_response_motorik||0)
        },
    },
    
    watch:{
        tingkatKesadaranTotal(v){
            setTimeout(()=>{
                this.isParent.row.aukptk_nilai_gcs = v
            },250)
        },
    }

    // notes
    // riwayat jatuh > ya => 6
    // status mental > ya => 14
    // Penglihatan > ya => 1
    // kebiasaan > ya => 2
    // trasnfer mobilitas 0 / 7
    // kalo dibawah 3 == 0
}
</script>