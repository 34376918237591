<template>

    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div class="phi-info d-flex flex-column col-md-1 ml-auto mr-3">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                        
                        <div class="d-flex flex-column">
                            <!--
                            <a href="javascript:;" v-if="row.kajianPerawat" @click="toDetailKajianPerawatUGD(row,row.aurm_ar_id)" data-toggle="modal" class="btn btn-outline-light">Lihat Kajian Perawat</a>
                            -->
                            <a href="javascript:;" @click="doOpenRiwayat(row)" data-toggle="modal" class="btn btn-outline-light mt-1">Lihat Riwayat Rekam Medis</a>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>

        <div class="content">
            <b-form @submit.prevent="handleSubmit(doSubmit())">
            <div class="card">
                <div class="card-header bg-white" style="border-bottom: 1px solid rgba(0,0,0,.125);">
                    <h6 class="card-title font-weight-semibold">Kajian Sekunder</h6>
                </div>
                <b-card-body>
                    <VStepTab :step.sync="stepTab" :last="Steps.length" :config="stepValidate">
                        <template slot="link">
                            <template v-for="(v,k) in Steps">
                                <VStepLink :step.sync="stepTab" :option="k + 1" :key="k" :clickable="(k+1) < stepTab">
                                    {{ v.subtitle }}
                                </VStepLink>
                            </template>
                        </template>
                        <template slot="content">
                            <template v-for="(v,k) in Steps">
                                <VStepContent :step.sync="stepTab" :option="k+1" :key="k">
                                    <template slot="title">
                                        {{ v.subtitle }}
                                    </template>
                                    <template slot="fieldset">
                                        <div>
                                            <validation-observer
                                                ref="VFormKajianAwal"
                                            >
                                                <KajianAwal v-if="stepTab == 1" />
                                            </validation-observer>
                                            <validation-observer
                                                ref="VFormKajianNyeri"
                                            >
                                                <KajianNyeri v-if="stepTab == 2" />
                                            </validation-observer>
                                            <validation-observer
                                                ref="VFormResikoJatuh"
                                            >
                                                <ResikoJatuh v-if="stepTab == 3" />
                                            </validation-observer>
                                            
                                            <validation-observer
                                                ref="VFormTingkatKesadaran"
                                            >
                                                <TingkatKesadaran v-if="stepTab == 4" />
                                            </validation-observer>

                                            <validation-observer
                                                ref="VFormGizi"
                                            >
                                                <SkriningGizi v-if="stepTab == 5" />
                                            </validation-observer>
                                            
                                            <validation-observer
                                                ref="VFormAsuhanKeperawatan"
                                            >
                                                <AsuhanKeperawatan v-if="stepTab == 6" />
                                            </validation-observer>

                                        </div>
                                    </template>
                                </VStepContent>
                            </template>
                        </template>
                        <template slot="action">
                            <ul role="menu" aria-label="Pagination">
                                
                                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>

                                <li :class="(stepTab==1?'disabled':'')" v-bind:aria-disabled="(stepTab==1?true:false)" @click="doPrev"><a href="javascript:" :class="'btn btn-light '+(stepTab==1?'disabled':'')" role="menuitem"><i class="icon-arrow-left13 mr-2"></i> Previous</a></li>

                                <li aria-hidden="false" aria-disabled="false" @click="doNext"><a href="javascript:" :class="'btn btn-primary '+(stepTab!=stepLast?'':'disabled')" role="menuitem">Next <i class="icon-arrow-right14 ml-2"></i></a></li>
                                

                                <li v-if="stepTab == stepLast" aria-hidden="false" aria-disabled="false"><button @click="doSubmit()"  type="button" class="btn btn-primary" role="menuitem">Submit<i class="icon-checkmark3 ml-2"></i></button></li>
                            </ul>
                        </template>
                    </VStepTab> 
                </b-card-body>
            </div>
            </b-form>
        </div>
        
        <b-modal v-model="openDetailKajianPerawatUGD" title="Kajian Sekunder" size="xl" hide-footer>
            <KajianPerawatUGD v-bind="passToSubPerawatUGD" /> 
        </b-modal>
        
        <b-modal v-model="openRiwayatRMUGD" title="Detail Riwayat Rekam Medis" size="xl" hide-footer>
            <RiwayatRMUGD v-bind="passToSubRMUGD" />
        </b-modal>

    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._

import VStepTab from '@/components/VStep/VStepTab.vue'
import VStepLink from '@/components/VStep/VStepLink.vue'
import VStepContent from '@/components/VStep/VStepContent.vue'

import KajianAwal from './KajianAwal.vue'
import KajianNyeri from './KajianNyeri.vue'
import ResikoJatuh from './ResikoJatuh.vue'
import TingkatKesadaran from './TingkatKesadaran.vue'
import SkriningGizi from './SkriningGizi.vue'
import AsuhanKeperawatan from './AsuhanKeperawatan.vue'

import Gen from '@/libs/Gen.js'

import RiwayatRMUGD from '@/components/RiwayatUGD/RiwayatRMUGD.vue'
import KajianPerawatUGD from '@/components/RiwayatUGD/KajianPerawatUGD.vue'


export default {
    extends: GlobalVue,
    data(){
        return {
            stepTab: 1,
            stepLast: 6,
            last_page: 1,
            rowReg: {},
            Steps: [
                {
                    icon: 'create',
                    name: 'kajian-awal',
                    subtitle: 'Kajian Awal',
                    component: KajianAwal,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'kajian-nyeri',
                    subtitle: 'Kajian Nyeri',
                    component: KajianNyeri,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'resiko-jatuh',
                    subtitle: 'Resiko Jatuh',
                    component: ResikoJatuh,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'tngkat-kesadaran',
                    subtitle: 'Tingkat Kesadaran',
                    component: TingkatKesadaran,
                    completed: false
                },  
                {
                    icon: 'create',
                    name: 'skrining-gizi',
                    subtitle: 'Skrining Gizi',
                    component: SkriningGizi,
                    completed: false
                },
                {
                    icon: 'create',
                    name: 'asuhan-keperawatan',
                    subtitle: 'Asuhan Keperawatan',
                    component: AsuhanKeperawatan,
                    completed: false
                }
            ],

            mAlergi: [],
            mKesadaran: [],
            mPekerjaan: [],
            mAlatBantu: [],
            mPendidikan: [],
            mPenghasilan: [],
            mAgama: [],
            diagnosa: [],
            
            mSDKI: [],
            mSLKI: [],
            mSIKI: [],
            
            openDetailKajianPerawatUGD: false,
            openRiwayatRMUGD: false,

            dataKajianPerawat: {},
            dataKajianDokter: {},

            historiKajianPerawat: [],
            historiKajianDokter: [],

            masterKajianPerawat: {},
            masterKajianDokter: {},

            masterTriase: {},
            dataKajianTriase: {},

            historiKajianRadiologi: [],
            historiKajianLab: [],
            historiKajianTriase: [],

            dataKajianRadiologi: {},
            dataKajianLab: {},

            dataKajianFarmasi: [],
            dataUGDReservasi: {},
            activeNyeri: '',
        }
    },
    methods: {   
         toDetailKajianPerawatUGD(v,regID){
            this.loadingOverlay = true
            if(v.kajianPerawat){
                Gen.apiRest(
                    "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id, 
                ).then(res=>{
                    this.openDetailKajianPerawatUGD = true    
                    this.dataKajianPerawat = res.data
                    this.loadingOverlay = false
                })
                
                Gen.apiRest(
                    "/get/"+'UGDFormPerawat'+'/'+this.row.ap_id+'/'+v.kajianPerawat+'?regId='+v.aurm_aur_id+'&master=1', 
                ).then(res=>{
                    this.masterKajianPerawat = res.data
                })
            }else{
                this.openDetailKajianPerawatUGD = true    
                this.loadingOverlay = false
            }
        },
        doOpenRiwayat(v){
            this.loadingOverlay = true
            
            if(v.kajianDokter){
                Gen.apiRest(
                    "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id, 
                ).then(res=>{
                    this.openRiwayatRMUGD = true    
                    this.dataKajianDokter = res.data
                    this.loadingOverlay = false
                })
            }else{
                this.openRiwayatRMUGD = true    
                this.loadingOverlay = false
            }

            if(v.kajianDokter){
                Gen.apiRest(
                    "/get/"+'UGDFormDokter'+'/'+this.row.ap_id+'/'+v.kajianDokter+'?regId='+v.aurm_aur_id+'&master=1', 
                ).then(res=>{
                    this.masterKajianDokter = res.data
                })
            }

            if(v.triase_id){            
                Gen.apiRest(
                    "/get/"+'UGDTriase'+'/'+v.triase_id+'?regId='+v.aurm_aur_id, 
                ).then(res=>{
                    this.dataKajianTriase = res.data
                })
            }
            
            if(v.triase_id){            
                Gen.apiRest(
                    "/get/"+'UGDTriase'+'/'+'master', 
                ).then(res=>{
                    this.masterTriase = res.data
                })
            }

            if(v.kajianLab){                        
                Gen.apiRest(
                    "/get/"+'UGDFormLab'+'/'+this.row.ap_id+'/'+v.kajianLab+'?regId='+v.aurm_aur_id, 
                ).then(res=>{
                    this.dataKajianLab = res.data
                })
            }
            
            if(v.kajianRadiologi){                        
                Gen.apiRest(
                    "/get/"+'UGDFormRadiologi'+'/'+this.row.ap_id+'/'+v.kajianRadiologi+'?regId='+v.aurm_aur_id, 
                ).then(res=>{
                    this.dataKajianRadiologi = res.data
                })
            }
            
            Gen.apiRest(
                "/do/"+'UGDAssesment',
                {data:{type:'get-riwayat-rekam-medis', aur_id: v.aurm_aur_id}}
            ).then(res=>{
                let resp = res.data
                this.historiKajianPerawat = resp.historyKajianPerawat
                this.historiKajianDokter = resp.historyKajianDokter
                this.historiKajianRadiologi = resp.historyKajianRadiologi
                this.historiKajianLab = resp.historyKajianLab
                this.historiKajianTriase = resp.historyKajianTriase
                this.loadingOverlay = false
            })

            if(v.aurm_aur_id){
                Gen.apiRest(
                    "/do/" + 'UGDAssesment', {
                        data: {
                        type: 'get-obat-farmasi',
                        regId: v.aurm_aur_id
                        }
                    }
                ).then(res => {
                    let resp = res.data
                    this.dataKajianFarmasi = resp.data
                    this.dataUGDReservasi = resp.dataUGDReservasi
                    this.loadingOverlay = false
                })
            }
            
            
            this.openRiwayatRMUGD = true
        },
        
        back(){
            this.$router.back()
        },

        apiGet(params = {}, page = 1){
            if(!this.pageSlug || !this.$route.query.regId){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })

            // master
            if(this.$route.params.rmNo){
                Gen.apiRest(
                    "/get/"+url+'?master=1', 
                    {
                    params: Object.assign({page: page}, paramsQuery, params.query||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }
            
        },

        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },
        doPrev(){
            if(this.stepTab !== 1){
                this.stepTab = this.stepTab -1
                this.row.aurm_last_step = this.stepTab
                this.autoSave(this.row)
                window.scrollTo(0,0)
            }
        },
        doNext(){
            if(this.stepTab < this.stepLast){
                if(this.stepTab == 1){
                    this.$refs['VFormKajianAwal'][0].validate().then(success=>{
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                else if(this.stepTab == 2){
                    this.$refs['VFormKajianNyeri'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.row.arj_tindakan_tambahan = this.row.arj_tindakan_tambahan ? ['Tidak Ada Tindakan'] : null
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 3){
                    this.$refs['VFormResikoJatuh'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 4){

                    
                    this.$refs['VFormTingkatKesadaran'][0].validate().then(success => {
                        if(success){
                            
                            if (!this.row.aukptk_response_mata || !this.row.aukptk_response_mata || !this.row.aukptk_response_motorik) {
                                return this.$swal({
                                    icon: 'error',
                                    title: 'Maaf, data yang Anda isi belum lengkap',
                                    text: 'Tingkat Kesadaran (GCS) Tidak Lengkap'
                                }).then(result => {
                                    if (result.value) {
                                    setTimeout(() => {
                                        window.scrollTo(0, 0)
                                    }, 500)
                                    }
                                })
                            }

                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 5){
                    this.$refs['VFormGizi'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else if(this.stepTab == 6){
                    this.$refs['VFormAsuhanKeperawatan'][0].validate().then(success => {
                        if(success){
                            this.stepTab = this.stepTab + 1
                            this.row.aurm_last_step = this.stepTab                
                            this.autoSave(this.row)
                        }else{
                            setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }else{
                    console.log("no action")
                }
                window.scrollTo(0,0)
            }
        },
        
        addMasterAlergi(){
            for(let i = 0; i < (this.row.aukpa_alergi||[]).length; i++){
                let newData = this.row.aukpa_alergi[i]
                if((newData.name||"").length && newData.jenis == "Obat"){
                    let dataPost = {
                        type : 'auto-save-riwayat',
                        name : newData.name
                    }  
                    Gen.apiRest(
                        "/do/"+'UGDFormPerawat',
                        {data:dataPost}, 
                        "POST"
                    )
                }
            }
        },
        doSubmit(){
            if(this.row.aurm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                }).then(result => {
                    if (result.value) {
                        setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    }
                })
            }   
            
            let inval = 0
            this.$refs['VFormKajianAwal'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 1
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormKajianNyeri'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 2
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                    
                }
            })
            this.$refs['VFormResikoJatuh'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 3
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })

            this.$refs['VFormTingkatKesadaran'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 4
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            
            this.$refs['VFormGizi'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 5
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            this.$refs['VFormAsuhanKeperawatan'][0].validate().then(success=>{
                if(!success){
                    this.stepTab = 6
                    inval = inval + 1
                    this.row.aurm_last_step = this.stepTab                
                    this.autoSave(this.row)
                    setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                }
            })
            setTimeout(()=>{
                if(inval){
                    return this.$swal({
                        icon: 'error',
                        title: 'Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                            if(el[i].style.display !== 'none'){
                                inv.push(el[i].id)
                            }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }
                if(!(this.diagnosa||[]).length){
                    return this.$swal({
                        icon: 'error',
                        title: 'Minimal harus ada 1 diagnosa'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }   

                let invalSiki = 0
                let invalSikiTab = []

                let invalSlki = 0
                let invalSlkiTab = []

                for(let i = 0; i < (this.diagnosa||[]).length; i++){
                    if(!(this.diagnosa[i]['siki']||[]).length){
                        invalSiki += 1
                        invalSikiTab.push(i+1)
                    }
                    if(!(this.diagnosa[i]['slki']||[]).length){
                        invalSlki += 1
                        invalSlkiTab.push(i+1)
                    }
                }
                
                if(invalSlki){
                    return this.$swal({
                        icon: 'error',
                        title: `SLKI Pada Tab Ke ${invalSlkiTab.join(', ')} Kosong`
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }   
                
                if(invalSiki){
                    return this.$swal({
                        icon: 'error',
                        title: `SIKI Pada Tab Ke ${invalSikiTab.join(', ')} Kosong`
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }   

                
                if(!inval){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        this.addMasterAlergi()
                        
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            data.diagnosa = this.diagnosa

                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'UGDFormPerawat',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    // allowOutsideClick: false,
                                    // allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.user.levelId == 1){
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.row.aurm_pasien_id }, query: {regId: this.row.aurm_aur_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.row.aurm_pasien_id }, query: {regId: this.row.aurm_aur_id} }).catch(()=>{})
                                        }
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.doSetAlertForm(err)
                            })
                            
                            // 934?regId=1367
                        }
                    })
                }
            },100)

        },
        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.aurm_last_step = this.row.aurm_last_step

            if(data.ap_usia <= 6 || data.ap_usia == null){
                data.aukpn_skala_nyeri_value = this.hitungSkala
            }
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'UGDFormPerawat',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),
        
        
        autoSaveDiagnosa: _.debounce(function (data) {
            let dataPost = {
                type : 'auto-save-diagnosa',
                data : data
            }  
            if(!this.row.isEdit){
                Gen.apiRest(
                    "/do/"+this.modulePage,
                    {data:dataPost}, 
                    "POST"
                )
            }
        },1000),  
        
        inputData: _.debounce(function (element) {
            let data = this.row
            data.activeNyeri = this.activeNyeri
            data.to = this.$route.query.regId
            data.users = "perawat"
            data.element = element

            console.log(element,data)

            this.$socket.emit('kajian',data)
        },1000)
    },  
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
    
        setTimeout(()=>{
            this.stepTab = this.row.aurm_last_step||1
        },1500)
    
        this.apiGet()
        
        this.$socket.emit('new_reg_id_kajian',this.$route.query.regId)

        this.sockets.subscribe('show_kajian', function(data) {
            if(data.users == "dokter"){
                if(data.element == 'keluhan_utama'){
                    this.row.aukpa_keluhan_utama = data.ausd_keluhan
                }
                if(data.element == 'riwayat'){
                    this.row.aukpa_riwayat_penyakit = data.ausd_riwayat
                }
                if(data.element == 'riwayat-old'){
                    this.row.aukpa_riwayat_penyakit_old = data.ausd_catatan_dokter
                }
                if (data.element == 'alergi') {
                    this.row.aukpa_alergi = data.ausd_alergi
                }
                if (data.element == 'has_alergi') {
                    this.row.aukpa_has_alergi = data.ausd_has_alergi
                }
                if (data.element == 'has_obat_rutin') {
                    this.row.aukpa_has_obat_rutin = data.ausd_has_obat_rutin
                }
                if (data.element == 'keterangan_obat_rutin') {
                    this.row.aukpa_keterangan_obat_rutin = data.ausd_keterangan_obat_rutin
                }
                if (data.element == 'tekanan_darah_min') {
                    this.row.aukpa_ttv_tekanan_darah_min = data.auod_ttv_tekanan_darah_min
                }
                if (data.element == 'tekanan_darah_max') {
                    this.row.aukpa_ttv_tekanan_darah_max = data.auod_ttv_tekanan_darah_max
                }
                if (data.element == 'nadi') {
                    this.row.aukpa_ttv_nadi = data.auod_ttv_nadi
                }
                if (data.element == 'label') {
                    this.row.aukpa_ttv_label = data.auod_ttv_label
                }
                if (data.element == 'gula_darah') {
                    this.row.aukpa_ttv_gula_darah = data.auod_ttv_gula_darah
                }
                if (data.element == 'pernafasan') {
                    this.row.aukpa_ttv_pernafasan = data.auod_ttv_pernafasan
                }
                if (data.element == 'spo2') {
                    this.row.aukpa_ttv_spo2 = data.auod_ttv_spo2
                }
                if (data.element == 'suhu') {
                    this.row.aukpa_ttv_suhu = data.auod_ttv_suhu
                }
                if (data.element == 'weight') {
                    this.row.aukpa_ttv_weight = data.auod_ttv_weight
                    this.row.aukpa_ttv_bmi = data.auod_ttv_bmi
                }
                if (data.element == 'height') {
                    this.row.aukpa_ttv_height = data.auod_ttv_height
                    this.row.aukpa_ttv_bmi = data.auod_ttv_bmi
                }
                if (data.element == 'lingkar_kepala') {
                    this.row.aukpa_ttv_lingkar_kepala = data.auod_ttv_lingkar_kepala
                }
                if (data.element == 'permukaan_anak') {
                    this.row.aukpa_ttv_luas_permukaan_anak = data.auod_ttv_luas_permukaan_anak
                }
                if (data.element == 'bmi') {
                    this.row.aukpa_ttv_bmi = data.auod_ttv_bmi
                }

                if (data.element == 'skala_nyeri_nips') {
                    this.row.aukpn_skala_nyeri_nips = data.auod_skala_nyeri_nips
                }
                if (data.element == 'skala_nyeri_value') {
                    this.row.aukpn_skala_nyeri_value = data.auod_skala_nyeri_value
                }
                if (data.element == 'skala_nyeri') {
                    this.row.aukpn_skala_nyeri = data.auod_skala_nyeri
                }
                if (data.element == 'response_mata') {
                    this.row.aukptk_response_mata = data.auod_response_mata
                }
                if (data.element == 'response_verbal') {
                    this.row.aukptk_response_verbal = data.auod_response_verbal
                }
                if (data.element == 'response_motorik') {
                    this.row.aukptk_response_motorik = data.auod_response_motorik
                }
                
                if (data.element == 'response_motorik') {
                    this.row.aukptk_response_motorik = data.auod_response_motorik
                }
                if (data.element == 'activeNyeri' || data.element == 'skala_nyeri_nips' || data.element == 'skala_nyeri') {
                    console.log(data.activeNyeri)
                    this.activeNyeri = data.activeNyeri
                }

                


                // if dokter lets flip

                let elem = document.getElementById(data.element)
                if(elem){
                    let elemtype = document.getElementById(data.element+"-typing")
                    elem.disabled = true
                    elemtype.classList.remove('d-none')        

                    setTimeout(()=>{
                        elem.disabled = false      
                        elemtype.classList.add('d-none')              
                    },1000)
                }
            }
        })

    },
    components: {
        VStepTab, VStepLink, VStepContent, KajianAwal, KajianNyeri, TingkatKesadaran, ResikoJatuh, SkriningGizi, AsuhanKeperawatan, RiwayatRMUGD, KajianPerawatUGD
    },
    computed: {
        stepValidate(){
            return {
                disabled:false,
                validate:true,
                refs:this.$children
            }
        },
        hitungSkala(){
            let value = 0
            if((this.row.ap_usia < 1 || (this.activeNyeri == 'NIPS')) && this.row.ap_usia != null){   
                for(let i = 0; i < (this.row.aukpn_skala_nyeri_nips||[]).length; i++){
                    value += this.row.aukpn_skala_nyeri_nips[i]['value']
                }   
            }
            if(this.row.ap_usia >= 1 || (this.activeNyeri == 'FL')){
                for(let i = 0; i < (this.row.aukpn_skala_nyeri||[]).length; i++){
                    value += this.row.aukpn_skala_nyeri[i]['value']
                }   
            }
            if(this.row.ap_usia > 6 || (this.activeNyeri == 'NP')){
                value += this.row.aukpn_skala_nyeri_value
            }

            return value
        },  
        passToSubPerawatUGD(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataKajianPerawat: this.dataKajianPerawat,
                historiKajianPerawat: this.historiKajianPerawat,
                masterKajianPerawat: this.masterKajianPerawat,
            })
        },

        passToSubRMUGD(){
            return _.assign({
                isAdd:this.isAdd,
                mrValidation:this.mrValidation,
                Config:this.Config,
                row: this.row,
                rowReg: this.rowReg,
                dataKajianDokter: this.dataKajianDokter,
                dataKajianTriase: this.dataKajianTriase,
                historiKajianDokter: this.historiKajianDokter,
                masterKajianDokter: this.masterKajianDokter,
                masterTriase: this.masterTriase,
                historiKajianRadiologi: this.historiKajianRadiologi,
                historiKajianLab: this.historiKajianLab,
                historiKajianTriase: this.historiKajianTriase,
                dataKajianRadiologi: this.dataKajianRadiologi,
                dataKajianLab: this.dataKajianLab,
                dataKajianFarmasi: this.dataKajianFarmasi,
                dataUGDReservasi: this.dataUGDReservasi
            })
        },
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
        
        diagnosa: {
            handler(v) {
                this.autoSaveDiagnosa(v)
            },
            deep: true
        },
        
        // autofill
        // 'row.aukpa_keluhan_utama'(v){
        //     this.inputData('keluhan_utama')
        // },

    }
}

</script>