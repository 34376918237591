<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-6" v-if="(isParent.row.ap_usia == null && isParent.row.ap_dob == null)">
                <v-select 
                    :options="mSkrining"
                    v-model="isParent.row.aukpg_skrining_gizi"
                    placeholder="Pilih Pengkajian Skrining Gizi"
                    label="text"
                :clearable="true" :reduce="v=>v.value"
                >
                </v-select>
            </div>
        </div>

        <template v-if="(isParent.row.ap_usia < 15 && isParent.row.ap_usia != null) || isParent.row.aukpg_skrining_gizi == '1'">
            <div class="card">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Screening Gizi metode STRONG KIDS</h5>
            </div>
            <div class="card-body">
                <table class="table table-bordered table-sm">
                <thead>
                    <tr>
                    <th width="60%">Pengkajian</th>
                    <th width="40%">Nilai</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td class="align-top">Apakah pasien memiliki status gizi kurang/buruk secara klinis? (anak kurus/sangat kurus, mata cekung, wajah tampak tua, edema, rambut tipis & jarang, otot lengan dan paha tipis, iga gambang, perut kempes, bokong tipis dan kisut)</td>
                    <td class="align-top">
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpg_is_status_gizi_kurang"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td class="align-top">Apakah ada penurunan BB selama 1 bulan terakhir? Atau untuk bayi 1th, BB tidak naik selama 3 bulan terakhir</td>
                    <td class="align-top">
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpg_is_bb_kurang"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td class="align-top">
                        <span>Apakah terdapat salah satu dari kondisi berikut?</span>
                        <ul class="mb-0">
                        <li>Diare profuse (>= 5x/hari) dan atau muntah (>= 3x/hari)</li>
                        <li>Asupan makan berkurang selama 1 minggu terakhir</li>
                        </ul>
                    </td>
                    <td class="align-top">
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpg_is_diare"
                        />
                    </td>
                    </tr>
                    <tr>
                    <td class="align-top">Apakah terdapat penyakit/keadaan yang beresiko malnutrisi?</td>
                    <td class="align-top">
                        <b-form-radio-group
                            :options="isParent.Config.mr.yesNoOpt"
                            v-model="isParent.row.aukpg_is_malnutrisi"
                        />
                        <div id="malnutrisiRisk" v-if="isParent.row.aukpg_is_malnutrisi">
                            <div class="mt-2">
                                <label for="malPenyakit">Sebutkan Penyakit <strong class="text-danger">*</strong></label>
                                <b-form-input v-model="isParent.row.aukpg_penyakit" type="text" name="malPenyakit" id="malPenyakit" placeholder="Nama Penyakit" class="form-control" />

                                <VValidate 
                                    name="Penyakit" 
                                    v-model="isParent.row.aukpg_penyakit" 
                                    :rules="{required : 1}"
                                />
                            </div>
                            <div class="mt-2">
                                <label for="malPenyakitCatatan">Catatan</label>
                                <textarea name="malPenyakitCatatan" id="malPenyakitCatatan" rows="2" placeholder="Catatan terkait penyakit" v-model="isParent.row.aukpg_catatan" class="form-control"></textarea>
                            </div>
                        </div>
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
            </div>
        </template>

        <!-- Pasien berusia >= 15 tahun -->
        <template v-else-if="isParent.row.ap_usia >= 15 || isParent.row.aukpg_skrining_gizi == '2'">
            <div class="card">
            <div class="card-header bg-info">
                <h5 class="card-title font-weight-semibold">Screening Gizi Metode Malnutrition Screening Tools</h5>
            </div>
            <div class="card-body">
                <div class="form-group">
                <h6>Apakah Pasien Mengalami Penurunan Berat Badan yang Tidak Diinginkan Selama 6 Bulan Terakhir?<strong class="text-danger">*</strong></h6>
                <div class="row">
                    <div class="col-md-12">
                    <table class="table table-bordered table-striped table-hover table-sm">
                        <thead>
                        <tr>
                            <th>PARAMETER</th>
                            <th>SKOR</th>
                        </tr>
                        </thead>
                        <tbody>
                        
                        <tr v-for="(v,k) in (Config.mr.cfPenurunanBeratUGD||[])" :key="k">
                            <td>
                                <b-form-radio v-model="isParent.row.aukpg_penurunan_berat" name="aukpg_penurunan_berat" :value="v.value">{{v.text}}</b-form-radio>
                            </td>
                            <td>{{v.number}}</td>
                        </tr>

                        <tr>
                            <td colspan="2">
                            <b-form-radio v-model="isParent.row.aukpg_penurunan_berat" name="aukpg_penurunan_berat" :value="'Y'"> Ya, dengan penurunan berat</b-form-radio>
                            </td>
                        </tr>

                        </tbody>
                        <tbody id="weighDownSelection" v-if="isParent.row.aukpg_penurunan_berat == 'Y'">
                        <tr v-for="(v,k) in Config.mr.cfPenurunanBeratUGDLain||[]" :key="k">
                            <td>
                            <div class="pl-3">
                                <div class="custom-control custom-radio">
                                <b-form-radio v-model="isParent.row.aukpg_penurunan_berat_detail" name="aukpg_penurunan_berat_detail" :value="v.value">{{v.text}}</b-form-radio>
                                </div>
                            </div>
                            </td>
                            <td>{{v.value}}</td>
                        </tr>
                        </tbody>
                        <tfoot>
                        <tr class="table-info">
                            <td colspan="99" class="text-info-700">
                            <span>NILAI SKALA GIZI: {{hitungValue}}</span>
                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-if="hitungValue > 1">TINGGI</span>
                            <span class="border-left ml-2 pl-2 font-weight-semibold" v-else>RENDAH</span>
                            </td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                    <div class="col-12">
                    <div class="alert alert-info">Skor lebih atau sama dengan 2 akan dilakukan kajian lanjut oleh Dokter Gizi</div>
                    </div>
                    <div class="col-12">
                    <div class="form-group">
                        <label for="">Apakah asupan makanan berkurang karena tidak nafsu makan?</label>
                        <div>
                            <b-form-radio-group
                                :options="isParent.Config.mr.yesNoOpt"
                                v-model="isParent.row.aukpg_tidak_nafsu"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="">Diagnosis Khusus Pasien</label>
                        <div class="form-row">
                        <div class="col-md-3">
                            <v-select placeholder="Pilih Diagnosa" v-model="isParent.row.aukpg_diagnosis_khusus_pasien" :options="Config.mr.diagnosaKhusus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        </div>
                        <div class="col-md-4" v-if="isParent.row.aukpg_diagnosis_khusus_pasien == 'L'">
                            <b-form-input v-model="isParent.row.aukpg_diagnosis_khusus_pasien_text" type="text" name="name" class="form-control" placeholder="Diagnosa Khusus Pasien Lainnya"/>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </template>

        <div class="card">
        <div class="card-header bg-info"><h5 class="card-title font-weight-semibold">Riwayat Penggunaan Obat</h5></div>
        <div class="card-body">
            <table class="table table-bordered table-sm">
            <thead>
                <tr>
                <th>#</th>
                <th>Nama Obat</th>
                <th>Dosis</th>
                <th>Waktu Penggunaan</th>
                <th>Aksi</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(v,k) in (isParent.row.aukpg_riwayat_penggunaan_obat||[])" :key="k">
                <td>{{k+1}}</td>
                    <td><b-form-input type="text" v-model="v.nama_obat" class="form-control"/></td>
                    <td><b-form-input type="text" v-model="v.dosis" class="form-control"/></td>
                    <td><b-form-input type="text" v-model="v.waktu_penggunaan" class="form-control"/></td>
                    <td> <a href="javascript:;" class="list-icons-item"
                    @click="(isParent.row.aukpg_riwayat_penggunaan_obat||[]).splice(k,1)"
                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                        class="icon-bin"></i></a></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                <td colspan="5" class="text-center">
                    <a href="javascript:;" @click="(isParent.row.aukpg_riwayat_penggunaan_obat||[]).push({
                        'nama_obat' : null,
                        'dosis' : null,
                        'waktu_penggunaan' : null,
                    })" class="btn btn-outline-info"><i class="icon-plus2 align-middle mr-2"></i><span class="align-middle">Tambah Obat</span></a>
                </td>
                </tr>
            </tfoot>
            </table>
        </div>
        </div>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
// import Datepicker from 'vuejs-datepicker'
const moment = require('moment')


export default{
    extends: GlobalVue,
    // components:{ 
    //     Datepicker
    // },
    
    data(){
        return {
            mSkrining: [
                {
                    text: 'Screening Gizi metode STRONG KIDS',
                    value: '1' 
                },
                {
                    text: 'Screening Gizi Metode Malnutrition Screening Tools',
                    value: '2' 
                }
            ]
        }
    },
    methods: {
        toValidate(val){
            return {...val}
        },
    },
    computed: {
        isParent(){
            return this.$parent.$parent.$parent.$parent
        },
        hitungValue(){
            let value
            if(this.isParent.row.aukpg_penurunan_berat == 'T'){
                value = 0
            }else if(this.isParent.row.aukpg_penurunan_berat == 'TY'){
                value = 2
            }else{
                value = this.isParent.row.aukpg_penurunan_berat_detail
            }
            
            return value 
        }
    },
    watch:{
        hitungValue(v){
            setTimeout(()=>{
                this.isParent.row.aukpg_penurunan_value = v
            },250)
        },
    }
}

</script>
